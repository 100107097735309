<template>
<!-- 登录 -->
  <div class="login">
      <div class="login-inner">
          <div class="log-main">
              <div class="log-left">
                  <img src="../../assets/hl.png" alt="">
              </div>
              <div class="log-right">
                  <div class="log-tit">
                    <h1>哒哒通欢迎您！</h1> 
                  </div>
                  <form id="loginFrom" name="loginFrom">
                      <div class="reg-row">
                        <el-input placeholder="请输入用户名*" id="username" v-model.trim="username" name="username" clearable></el-input>
                        <p class="error" v-show="usernameShow">
                            <span class="tip"></span>
                            <span class="info">请输入用户名</span>
                        </p>
                    </div>
                    <div class="reg-row">
                        <el-input placeholder="请输入密码*" id="password" name="password" v-model="password" clearable show-password></el-input>
                        <p class="error" v-show="passwordShow">
                            <span class="tip"></span>
                            <span class="info">请输入密码</span>
                        </p>
                    </div>
                    <div class="reg-row ver">
                        <div class="ver-cont">
                            <el-input placeholder="请输入验证码" maxlength="4" id="code" name="code" v-model="code" clearable></el-input>
                            <div class="verificationCode" @click="changeVerificationCode()">
                                <img :src="codeImg" alt="" title="看不清？点击再换一张">
                            </div>
                        </div>
                        <p class="error" v-show="codeShow">
                            <span class="tip"></span>
                            <span class="info">请输入验证码</span>
                        </p>
                    </div>
                    <div class="forgetP">
                       <router-link to="/findPassword"  >忘记密码？</router-link>
                    </div>
                    <div class="reg-row">
                        <button type="button" class="reg-btn" @click="login()">登录</button>
                    </div>  
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    import Login from './Login.js';
    export default Login;
</script>

<style scoped>
    @import './Login.css';
</style>