import axios from 'axios'
import Login from './Login.vue'
import bus from '../../bus'
export default {
    components: {
        Login
    },
    data() {
        return {
            username:'',
            password:'',
            code:'',
            codeImg:'',
            usernameShow:false,
            passwordShow:false,
            codeShow:false,
            codeToken:'',
        }
    },
    created(){
        this.changeVerificationCode()
        localStorage.removeItem("DDTtoken")
        bus.$emit('logout',1)
    },
    mounted() {
        window.addEventListener('keydown',this.keyDown)
    },
    methods:{
        // 验证码
        changeVerificationCode(){
            axios.get( this.GLOBAL.BASE_URL1 + '/user/get/code',{
                params:{}
            })
            .then(res=>{
                if(res){
                    this.codeImg = res.data.data.creator
                    this.codeToken = res.data.code_token
                }
              
            })
            .catch(error=>{
                console.log(error);
            })
        } ,
        login(){
            if(this.username === ''){
                this.usernameShow = true
            }else if(this.password === ''){
                this.usernameShow = false
                this.passwordShow = true
            }else if(this.code === ''){
                this.passwordShow = false
                this.codeShow = true
            }else {
                this.codeShow = false
                var loginFrom = document.getElementById('loginFrom')
                var fd = new FormData(loginFrom)
                fd.append('code_token',this.codeToken)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
                axios.post( this.GLOBAL.BASE_URL1 + '/user/login',
                    fd
                )
                .then(res=>{
                    console.log(res);
                    if(res.data.status_code === 0){
                        this.$message({
                            type: 'success',
                            message: '登录成功',
                            showClose: true,
                            center: true
                        });
                        localStorage.setItem('DDTtoken',res.data.token)
                        bus.$emit('useMsg',res.data) 
                        this.$router.push('/')
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                    
                })
                .catch(error=>{
                    console.log(error);
                })
            }
        },
        keyDown(e){
            //如果是回车则执行登录方法
          if(e.keyCode == 13){
            this.login();
          }
        }
    
    },
    destroyed(){
        window.removeEventListener('keydown',this.keyDown,false);
    }
}